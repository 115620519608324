import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { VisiblyProps } from 'components/Props/visibly'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Engagementsec from 'components/Engagementsec/Engagementsec'
import Resulttwocol from 'components/Resulttwocol/Resulttwocol'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const VisiblyPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #e5e5e5;
          }
        }
      }
    }
  }
  .casepower-section {
    background-image: linear-gradient(
      to bottom,
      rgba(252, 253, 255, 0),
      rgb(247, 247, 247)
    );
      padding-bottom: 100px;
      ${xl(` 
        padding-bottom: 80px;
    `)} 
    ${lg(`
        padding-bottom: 60px;
    `)} 
    ${md(`
        padding-bottom: 40px;
    `)} 
  }

  .Casecontentfullwimg figure {
    margin: 67px 0 70px;
    ${lg(`
         margin: 50px 0 40px;
    `)}
    ${md(`
         margin: 30px 0 20px;
    `)}
  }
`

const Visibly = memo(props => {
  const { data } = props
  const { location } = props
  let item = [
    props.data.webPortalScreen.childImageSharp.fluid,
    props.data.fleetManagersScreen.childImageSharp.fluid,
    props.data.mobileEvCharging.childImageSharp.fluid,
  ]
  const designImg = props.data.techArchitechDia.childImageSharp.fluid
  let productImg = [
    props.data.mixpanel.childImageSharp.fluid,
    props.data.mixpanel2.childImageSharp.fluid,
  ]
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Visibly"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <VisiblyPage>
        <Casebanner
          bannerdata={VisiblyProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={VisiblyProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Engagementsec
            engagementsecdata={VisiblyProps}
            {...props}
            showFullimages={false}
          />
        </div>
        {showContent && (
          <>
            <Resulttwocol resulttwocoldata={VisiblyProps} {...props} />
            <CasePowerTools
              CasePowerToolsdata={VisiblyProps.CasePowerToolsProps}
              items={item}
            />
            <Casecontentfullwimg
              casecontentfullwdata={VisiblyProps.casecontentfullwProps}
              fluid={designImg}
            />
            <Reliabilityinstancessec
              ReliabilityinstancesData={VisiblyProps.ReliabilityinstancesProps}
              {...props}
              fluid={productImg}
            />
            <Relatedcaselist relatedcaselistdata={VisiblyProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={VisiblyProps} />
          </>
        )}
      </VisiblyPage>
    </Layout>
  )
})

export default Visibly

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/visiblybanner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihmainscreen: file(relativePath: { regex: "/fihmainscreen@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1166) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    overviewBanner: file(
      relativePath: { regex: "/visibly-vision-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 633) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webPortalScreen: file(relativePath: { regex: "/eye-test-screen@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fleetManagersScreen: file(
      relativePath: { regex: "/visibly-vision-test-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mobileEvCharging: file(
      relativePath: { regex: "/visibly-assessment-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    techArchitechDia: file(
      relativePath: { regex: "/visibly-responsive-img@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel: file(
      relativePath: { regex: "/google-analytics-product@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel2: file(relativePath: { regex: "/applause-product@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/ona-dating-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/fillinrx-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
