import React from 'react'
import { ResulttwocolStyles } from './Resulttwocol.styles'
import Image from 'components/ImgOptimized'

function Resulttwocol(props) {
  const { ResulttwocolProps } = props.resulttwocoldata
  const {
    data: {
      overviewBanner: {
        childImageSharp: { fluid: overviewBanner },
      },
    },
  } = props
  return (
    <ResulttwocolStyles>
      <div className="container">
        <h2>{ResulttwocolProps.heading}</h2>
        <div className="row">
          <div className="content-col">
            {ResulttwocolProps.subHead ? (
              <h3>{ResulttwocolProps.subHead}</h3>
            ) : (
              ''
            )}
            <p>{ResulttwocolProps.para}</p>
            {ResulttwocolProps.resultServicelists ? (
              <div className="result-services">
                {ResulttwocolProps.resultServicelists.map(
                  (resultServicelist, i) => {
                    return (
                      <div
                        key={i}
                        className={`single-service ${resultServicelist.className}`}
                      >
                        <div className="icon">
                          <img src={resultServicelist.icon} alt="" />
                        </div>
                        <div className="content-blk">
                          <h4>{resultServicelist.title}</h4>
                          <p>{resultServicelist.content}</p>
                          {resultServicelist.bulletList ? (
                            <ul>
                              {resultServicelist.bulletList.map(
                                (bulletpoint, i) => {
                                  return <li key={`${i}${i}`}>{bulletpoint}</li>
                                }
                              )}
                            </ul>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="img-col">
            <Image
              fluid={overviewBanner}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 642,
              }}
            />
          </div>
        </div>
      </div>
    </ResulttwocolStyles>
  )
}

export default Resulttwocol
