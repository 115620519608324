import styled from 'styled-components'
import { bodyFont, xl, lg, md } from 'config/variables'

export const ResulttwocolStyles = styled.div`
  position: relative;
  padding-bottom: 100px;
  margin-bottom: 126px;
  ${xl(`
      margin-bottom: 100px;
      padding-bottom: 80px;
  `)} 
   ${lg(`
      margin-bottom: 100px;
      padding-bottom: 80px;
  `)} 
  ${md(`
      margin-bottom: 40px;
      padding-bottom: 40px;
  `)} 
  &:after {
    content: '';
    width: 100%;
    height: 827px;
    background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .row {
    display: flex;
    justify-content: space-between;
    ${xl(`
          margin:0 -20px;
      `)} 
      ${lg(`
          margin:0;
          flex-direction: column;
      `)}  
    .content-col {
      flex: 0 1 460px;
      ${xl(`
          flex: 1 1 auto;
          padding:0 20px;
      `)} 
       ${lg(`
          padding:0px 0 40px;
          flex: 1 1 auto;
      `)}  
      ${md(`
          padding:0px 0 20px;
      `)}  
      h3 {
        margin-bottom:7px;
      }
      .result-services {
        margin-top: 52px;
        border: 1px solid #e8e9f3;
        background: rgba(252, 253, 255, 0.5);
        ${md(`
          margin-top: 40px;
      `)}  
        .single-service {
          padding: 36px;
          border-bottom: 1px solid #e8e9f3;
          display: flex;
          ${md(`
              padding: 20px 24px 20px 16px;
              flex-direction: column;
          `)} 
          &:last-child {
            border-bottom: 0;
          }
          .icon {
            flex: 0 1 50px;
            margin-right: 24px;
            ${md(`
              margin-right: 0;
              margin-bottom:16px;
          `)} 
          }
          .content-blk {
            flex: 1;
            h4 {
              font-size: 22px;
              line-height: 30px;
              font-family: ${bodyFont};
              margin-bottom: 7px;
              font-weight: 600;
              color: #85c26a;
              ${md(`
                  font-size: 20px;
                  line-height: 26px;
                   margin-bottom: 9px;
              `)} 
            }
            p {
              font-size: 18px;
              line-height: 24px;
              margin: 0;
            }
            ul {
              padding-left: 22px;
              li {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }
          &.pale-orange {
            .content-blk {
              h4 {
                color: #f6a25f;
              }
            }
          }
          &.robin-s-egg {
            .content-blk {
              h4 {
                color: #63d5ff;
              }
            }
          }
          &.carnation {
            .content-blk {
              h4 {
                color: #ef7c91;
              }
            }
          }
        }
      }
    }
    .img-col {
      flex: 0 1 633px;
      ${xl(`
          flex: 0 0 540px;
          padding:0 20px;
      `)} 
      ${lg(`
          flex: 1 1 auto;
          max-width:540px;
          align-self: center;
           width: 100%;
      `)}  
    }
  }
`
