import React from 'react'
import { images } from 'config/images'

export const VisiblyProps = {
  bannerProps: {
    heading: 'Visibly',
    bannerPara: 'get your vision prescription in five minutes',
    BannerAlt: 'Visibly Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        More than 50 million Americans have not undergone a vision screening or
        any eye test in the last 2 years. Out of them, 6.4 million people are
        found affected with vision impairment and they experience uncorrectable
        symptoms like trouble seeing, inability to focus, drive, double vision
        et al. <br /> <br /> Visibly wanted to create a digital platform which
        could be an alternative to the traditional lab coat and lens approach
        for diagnosis. It’s mission was to revolutionize eye care industry by
        creating the first of its kind online eye exams. These set of exams
        would help customers get glasses prescription right from their computers
        or tablets, instead of seeing a doctor in person.
      </>
    ),
  },
  EngagementsecProps: {
    Title: 'Engagement',
    subTitle: <>Rapid development and quick iterations</>,
    para: (
      <>
        Developing an alternative to replace something which is in place for
        years, requires ingenious engineering and proper research. One of the
        biggest challenges was to ensure 100% accuracy in an online vision
        screening test. When the founders of Visibly approached Simform, they
        wanted to check the feasibility with a quick prototype that can be used
        by beta testers.
        <br /> <br /> {''}
        Impressed with our product development track record, the Co-founders of
        Visibly worked with our dedicated team to develop their first version of
        the Visibly platform. We started public testing because of the
        flexibility to run multiple test cycles in a short period. This allowed
        us to collect the large samples that helped in checking the success rate
        of the vision screening test. We delivered the prototype with a 99.6%
        success rate which helped Visibly in raising the initial funds.
      </>
    ),
  },
  ResulttwocolProps: {
    heading: 'Result',
    subHead: 'Vision Screening in few minutes',
    para: (
      <>
        We built a responsive web-app that can complete eye test in ten to
        fifteen minutes with just a smartphone or desktop. The test can be
        completed with basic items and in a small room, which eradicates the
        need of visiting doctors anymore. After completing the online eye test,
        the user will receive the prescription for glasses if needed in less
        than 24 hours from a doctor in just $35, which is 75% less than the
        actual cost.
      </>
    ),
  },
  CasePowerToolsProps: {
    mainTitle: 'Software that digitize the eye test',
    CasePowerToolsDetails: [
      {
        SubTitle: '',
        para:
          'Doctors believes that clinic visit is needlessly burdensome. This need can be easily addressed  by using telemedicine, especially in the case of vision screening test. ',
      },
      {
        SubTitle: '',
        para:
          'The main purpose of Visibly is to simplify the screening test, Hence it was kept fairly simple to conduct. After signing up, the user selects the purpose of the vision test i.e. whether it is for glasses, contact lenses or both. To make it more accurate, we added the number of heel-to steps that user would be required to take in order to stand 10 feet away from the screen.',
      },
      {
        SubTitle: '',
        para:
          'After all the set-up is complete, the application will run an assessment test by displaying various fonts on the desktop screen. User can enter all the input using smartphone. After all the input is captured, it will be shared via email with doctor and responses are then measured & determined on a 20/20 scale.',
      },
    ],
  },
  casecontentfullwProps: {
    heading: 'Highly responsive UI design',
    para: (
      <>
        To ensure high precision of screening, our target was to build sterling
        UI. We had to create a dynamic design that was highly precise and
        adaptable based on parameters such as screen size, screen resolution,
        DPI and the type of web browser. Even the slightest error in the test
        would result in an incorrect prescription.
      </>
    ),
    bottomPara:
      'To match the user’s expectations, we created eye tests which are highly animated and interactive. We leveraged HTML5, CSS3 along with AJAX to create faster and interactive web-app to deliver the accurate screening tests. Also, JavaScript is used to control the size of fonts that appear on the screen in a certain time-interval to capture the visibility state of the eye.',
  },
  ReliabilityinstancesProps: {
    trackheading: 'Crowd testing approach for getting best product',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara:
          'To measure the accuracy of tests, we used Google analytics for user behaviour tracking, usability testing, and reporting in conjunction with crowd sourced eye testing offered by Applause. We received positive feedback from beta testers enrolled through Applause along with some critical suggestions which helped us improve the platform before we went live for the general public.',
      },
      {
        trackbottomPara:
          'The results were statistically equivalent to the refractive exams done in the doctor’s office. This solution allowed them to march towards their next milestone & since then, Visibly has built their own development team.',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'ONA Dating',
        para: 'enhance your dating experience with an online ‘Matchmaker’',
        className: 'pale-peach',
        link: 'case-studies/ona-dating/',
      },
      {
        title: 'FillinRx',
        para: 'a pharmacy staffing solution',
        className: 'mint',
        link: '/fill-in-rx-pharmacy-staffing/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
