import styled from 'styled-components'
import { primaryColor, xl, lg, md, sm } from 'config/variables'

export const EngagementsecStyles = styled.div`
  margin-bottom: 120px;
  ${xl(`
      margin-bottom: 90px;
  `)} 
  ${lg(`
      margin-bottom: 60px;
  `)} 
   ${md(`
      margin-bottom: 40px;
  `)} 
  .animationImageRow {
    margin-bottom: 120px;
    ${xl(`
        margin-bottom: 90px;
    `)} 
    ${lg(`
        margin-bottom: 60px;
    `)} 
    ${md(`
        margin-bottom: 40px;
    `)} 
  }
  .mobileAppsheading {
    ${md(`
      display:none;
    `)}  
    h4 {
      font-size: 15px;
      font-weight: 600;
      margin: 0px;
      letter-spacing: -0.16px;
      line-height: 21px;
      text-transform: uppercase;
      margin-left: -30px;
      ${xl(`
           margin-left: 0;
      `)} 
      ${lg(`
           margin-left: 0;
      `)} 
    }
  }
  .mobileAppsContent {
    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
    ${md(`
       flex-direction: column;
    `)}  
    .mobileAppsContentLeft {
      flex: 0 1 465px;
      padding-right: 40px;
      padding-left: 84px;
      ${xl(`
           padding-left: 124px;
           padding-right: 25px;
           flex: 0 1 472px;
      `)} 
      ${lg(`
           padding-right: 15px;
           padding-left: 124px;
           flex: 0 1 332px;
      `)} 
      ${md(`
        flex:1 1 auto;
        padding:0px;
      `)}  
      .mobileAppsTitle {
        position: relative;
        padding: 40px 0;
        ${md(`
          padding: 23px 0 0;
        `)}  
        &:after {
          content: '';
          position: absolute;
          top: -12px;
          left: 0px;
          width: 100%;
          height: 3px;
          background: ${primaryColor};
          ${md(`
              top: 0;
              width:120px;
          `)}  
        }
        h2 {
          margin-bottom: 0px;
          ${md(`
            margin-bottom: 20px;
        `)} 
        }
      }
    }
    .mobileAppsContentRight {
      flex: 0 1 665px;
      padding-left: 40px;
      ${xl(`
           padding-left: 25px;
           flex: 0 1 469px;
      `)} 
      ${lg(`
           padding-left: 15px;
           flex: 0 1 350px;
      `)} 
       ${md(`
            padding:0px;
            flex: 1 1 auto;
        `)} 
      .mobileAppsDetails {
        position: relative;
        padding: 40px 0 0;
        ${md(`
            padding:0px;
        `)} 
        &:after {
          content: '';
          position: absolute;
          top: -12px;
          left: 0px;
          width: 100%;
          height: 3px;
          background: ${primaryColor};
          ${md(`
            display:none;
        `)} 
        }
      }
    }
  }

  .imgAnimationRow {
    overflow:hidden;
    .imgAnimationInner {
      display:flex;
      max-width:991px; 
      width:100%;
      height:669px;
      margin:0 auto;
      position: relative; 
      ${lg(`
        max-width:688px; 
        height:459px;
      `)}
      ${md(`
        max-width:420px; 
        height:292px;
      `)}
      ${sm(`
        max-width:280px; 
        height: 190px;
      `)}
      .imgAnimationCol {
        position: absolute; 
        width:auto;  
        will-change: transform, opacity;
        transform: translate3d(150px, 30px, 0px) scale(0.8); 
        opacity:0;
        transition-delay: 0.6s;
        &.imgAnimationCol0 {
          top:0px;
          left:0px;
          width:713px;
          height:auto;
          transition: all 1.3s;
          ${lg(`
              width: 500px;
          `)} 
          ${md(`
              width: 320px;
          `)} 
          ${sm(`
              width: 210px;
          `)} 
        }
        &.imgAnimationCol1 { 
          top:119px;
          right:0px;
          width:414px;
          height:auto;
          transition: all 1.3s 0.2s;
          ${lg(`
            top: 80px; 
            width: 290px;
          `)} 
          ${md(`
            top: 60px;
            width: 170px;
          `)} 
          ${sm(`
            top: 39px;
            width: 113px;
          `)} 
        }
        &.imgAnimationCol2 { 
          bottom:-9px;
          right: 324px;
          width:429px;
          height:auto;
          transition: all 1.3s 0.3s;
          transform: translate3d(-270px, 30px, 0px) scale(0.8); 
          ${lg(`
            right: 200px;
            width: 320px;
          `)} 
           ${md(`
            right: 130px;
            width: 190px;
          `)} 
           ${sm(`
            right: 90px;
            width: 120px;
          `)} 
        }
      }
      &.isShow {
        .imgAnimationCol {   
          opacity:1;
          will-change: transform, opacity;
          transform: translate3d(0px, 0px, 0px) scale(1);  
        }
      }
    }
  }
`
