import React from 'react'

import { EngagementsecStyles } from './Engagementsec.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import Picture from 'components/Picture/Picture'

function AnimationRow(props) {
  const [isVisible, setVisible] = React.useState(false)
  const domRef = React.useRef()
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    })
    observer.observe(domRef.current)
  }, [])
  return (
    <div className="imgAnimationRow">
      <div
        className={`imgAnimationInner ${isVisible ? 'isShow' : 'isHide'}`}
        ref={domRef}
      >
        {props.animationImageBlock.map((imageData, i) => {
          return (
            <div key={i} className={`imgAnimationCol imgAnimationCol${i}`}>
              <Picture
                height={70}
                offset={2000}
                srcSet={imageData.imgUrl2x}
                src={imageData.imgUrl}
                alt="Filipa"
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

function Engagementsec(props) {
  const {
    engagementsecdata: { EngagementsecProps },
    showFullimages,
  } = props
  const caseQuoteProp = EngagementsecProps.caseQuoteProps
  const {
    data: {
      fihmainscreen: {
        childImageSharp: { fluid: fihmainscreen },
      },
    },
  } = props
  return (
    <EngagementsecStyles className="engagementSec">
      <div className="container">
        {EngagementsecProps.imgData && (
          <div className="animationImageRow">
            <AnimationRow animationImageBlock={EngagementsecProps.imgData} />
          </div>
        )}
        <div className="mobileAppsheading">
          <h4>{EngagementsecProps.Title}</h4>
        </div>
        <div className="mobileAppsContent">
          <div className="mobileAppsContentLeft">
            <div className="mobileAppsTitle">
              <h2>{EngagementsecProps.subTitle}</h2>
            </div>
          </div>
          <div className="mobileAppsContentRight">
            <div className="mobileAppsDetails">
              <p>{EngagementsecProps.para}</p>
            </div>
          </div>
        </div>
        {showFullimages && (
          <div className="fihmainscreen">
            <Image
              fluid={fihmainscreen}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 680,
              }}
            />
          </div>
        )}
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </EngagementsecStyles>
  )
}

export default Engagementsec
